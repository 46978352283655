.tile {
   background: #7b7b7b;
   border: 1px solid #fff;
   float: left;
   line-height: 16px;
   height: 16px;
   text-align: center;
   width: 16px;
   cursor: pointer;
   border-radius: 3px;
   color: #fff;
   font-weight: 600;
   font-family: 'Poppins', sans-serif;

   &:focus {
     outline: none;
   }

   &.revealed {
     background: #C0C0C0;
   }

   &.explosive {
     color: #FC543C;
   }
 }
