.minesweeperContainer {
  margin: 0 auto;
  display: block;

  &.easy {
    width: 215px; //easy
  }

  &.medium {
    width: 280px; //medium
  }

  &.hard {
    width: 375px;; //hard
  }
}

.dificultyOptions {
  height: auto;
  margin-bottom: 10px;

  button {
    font-family: 'Poppins', sans-serif;
    margin-right: 5px;
    margin-top: 5px;
    border-radius: 3px;
    color: #FFFFFF;
    height: 30px;
    width: auto;
    background-color: #05f;
    border: none;
    font-weight: bold;
  }
}
