$board-width: 730px;
$counter-height: 40px;

.boardContainer {
  background-color: #FAFAFA;
  text-align: center;
  display: inline-block;
  border: 1px solid gray;
  margin: 0 auto;
  padding: 5px;
  border-radius: 3px;
  height: 100%;

  .lost {
    font-family: 'Poppins', sans-serif;
    color: red;
    font-size: 30px;
  }

  &.easy {
    width: 190px; //easy
  }

  &.medium {
    width: 280px; //medium
  }

  &.hard {
    width: 375px;; //hard
  }

  .boardRow {
    display: flex;
    flex-wrap: wrap;

    >* {
      flex: 1 1 16px;
    }
  }
}

.boardHeader {
  height: 50px;
  width: auto;
  border: none;
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  .controlContainer {
    color: white;
    display: flex;
    flex: 1;
  }

  .reset {
    height: $counter-height;
    width: auto;
    background: #05f;
    border-radius: 3px;
    border: none;
    color: #FFFFFF;
    font-weight: bold;
    margin: 0 auto;
    font-size: 13px;
    font-family: 'Poppins', sans-serif;
  }

  .counter {
    height: $counter-height;
    line-height: 18px;
    width: 80px;
    font-size: 13px;
    background: #FFFFFF;
    border: 1px solid #05f;
    border-radius: 3px;
    color: #05f;
    font-weight: bold;
    outline: 0;
    margin: 0 auto;
    font-family: 'Poppins', sans-serif;
  }
}


.clear {
  clear: both;
  content: "";
}
